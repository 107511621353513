import { computed, watch } from 'vue'
import { type RouteConfig } from 'vue-router'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useOtp } from '/~/extensions/otp/composables'
import { useKyc } from '/~/extensions/otp/composables/use-kyc'
import { useUser } from '/~/composables/user'

const { user, fetchUserDetails } = useUser()

export const hasUnfinishedVerifications = computed(() => {
  const { isEnrolmentOtpEnabled } = useOtp()
  const { isKycEnabled } = useKyc()

  return (
    (isEnrolmentOtpEnabled.value && user.value.hasUnfinishedOtpVerifications) ||
    (isKycEnabled.value && user.value.isAwaitingIdentityVerification)
  )
})

const channel = new BroadcastChannel('verifications')

channel.onmessage = (e) => {
  const { key, value } = e.data

  if (key === 'hasUnfinishedVerifications') {
    if (value !== hasUnfinishedVerifications.value) {
      fetchUserDetails()
    }
  }
}

watch(hasUnfinishedVerifications, (value) => {
  channel.postMessage({
    key: 'hasUnfinishedVerifications',
    value,
  })
})

export const unverifiedVerificationsGuard: RouteConfig['beforeEnter'] = (
  _,
  __,
  next
) => {
  if (hasUnfinishedVerifications.value) {
    next({ name: 'home', params: { hasUnfinishedVerifications: 'true' } })
  } else {
    next()
  }
}

export function useVerifications() {
  const router = useRouter()

  function showVerificationModal(): Promise<void> {
    return new Promise((resolve, _) => {
      const modalController = ui.mobile ? bottomSheet : modal

      modalController.show('verification-warning', {
        props: {
          onClose: () => resolve,
          onCancel: () => resolve,
          onSubmit: () => {
            router.push({ hash: '#profile-verification' })
            resolve()
          },
        },
      })
    })
  }

  function withVerificationsCheck(callback?: () => void) {
    if (hasUnfinishedVerifications.value) {
      showVerificationModal()
    } else if (typeof callback === 'function') {
      callback()
    }
  }

  async function verificationsCheck(): Promise<void> {
    if (hasUnfinishedVerifications.value) {
      if (router.currentRoute.name === 'home') {
        await router.push({ name: 'home' })
        showVerificationModal()
      } else {
        await router.push({
          name: 'home',
          params: { hasUnfinishedVerifications: 'true' },
        })
      }
    }
  }

  return {
    hasUnfinishedVerifications,
    withVerificationsCheck,
    showVerificationModal,
    verificationsCheck,
  }
}
